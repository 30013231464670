import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { SectionWrapper } from '../../../components/common/wrappers/Wrappers'
import { breakpoint, color, transition, opacity } from '../../../configs/utilities'
import { SectionTitle, SectionDescription, SectionText } from '../../../components/common/typography/Typography'
import { GatsbyImage } from 'gatsby-plugin-image'

const GraphicProjectsSection = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allUxUiProjectsJson {
        edges {
          node {
            path
            title
            role
            software
            text
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 750
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  breakpoints: [768, 1380]
                )
              }
            }
          }
        }
      }
    }
  `)
  const cardData = data.allUxUiProjectsJson.edges
  return (
    <SectionWrapper>
      <Wrapper>
        <SectionTitle>{intl.formatMessage({ id: 'graphic.title' })}</SectionTitle>
        <SectionDescription>{intl.formatMessage({ id: 'graphic.subTitle' })}</SectionDescription>
      </Wrapper>
      <ProjectWrapper>
        {cardData.map((item, index) => {
          return (
            <Project key={index}>
              <TextWrapper>
                <Title>{intl.formatMessage({ id: item.node.title })}</Title>

                <Description>
                  <p>{intl.formatMessage({ id: item.node.role })}</p>
                  <p>{intl.formatMessage({ id: item.node.software })}</p>
                </Description>
                <SectionText>{intl.formatMessage({ id: item.node.text })}</SectionText>
                <LinkWrapper>
                  <a href={item.node.path} target="_blank" rel="noopener noreferrer">
                    {item.node.path}
                  </a>
                </LinkWrapper>
              </TextWrapper>
              <ImageWrapper>
                <GatsbyImage image={item.node.image.childImageSharp.gatsbyImageData} alt={item.node.title} />
              </ImageWrapper>
            </Project>
          )
        })}
      </ProjectWrapper>
    </SectionWrapper>
  )
}

export default injectIntl(GraphicProjectsSection)

const Wrapper = styled.div`
  @media screen and (min-width: ${breakpoint.md}) {
  }
`

const ProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 80px;
  margin-top: 50px;
  @media screen and (min-width: ${breakpoint.md}) {
    column-gap: 40px;
  }
`

const Project = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: ${breakpoint.md}) {
    flex-direction: row;
    column-gap: 20px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${breakpoint.md}) {
    width: 50%;
    max-width: 600px;
  }
`

const Title = styled.div`
  font-size: 25px;
  font-weight: 700;
`

const Description = styled.div`
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  opacity: 0.5;
  margin: 10px 0 30px;
  p {
    padding: 2px 0;
  }
`

const LinkWrapper = styled.div`
margin-top: 10px;
  a {
    font-size: 14px;
    color: ${color.blue};
    transition: ${transition.default};

    @media (hover: hover) {
      &:hover {
        opacity: ${opacity.default};
      }
    }
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  @media screen and (min-width: ${breakpoint.md}) {
    width: 50%;
    justify-content: flex-end;
    margin-top: 0;
  }
  @media screen and (min-width: ${breakpoint.xl}) {
       img {
        max-width: 400px;
        max-height: 400px;
       }
   }
   @media screen and (min-width: ${breakpoint.xxl}) {
       img {
        max-width: 600px;
        max-height: 600px;
       }
   }
`



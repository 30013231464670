import React from 'react'
import Layout from '../../components/layout/Layout'
import GraphicProjectsSection from '../../sections/projects/graphic/GraphicProjectsSection'

const Graphic = () => {
  return (
    <Layout pageName="graphic">
      <GraphicProjectsSection/>
    </Layout>
  )
}

export default Graphic